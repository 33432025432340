document.addEventListener('DOMContentLoaded', event => {
	// Interface for Ahpra number fields.
	ahpraFieldLogic();
	// Same address checkbox logic.
	sameAddressFieldLogic();
	// Auto-population of training dates.
	trainingDateFieldPopulationLogic();
});

function ahpraFieldLogic() {
	const ahpraFields = document.querySelectorAll('.gfield.ahpra_number input');

	if (ahpraFields.length === 0) {
		return;
	}

	for (const $ahpraField of ahpraFields) {
		$ahpraField.addEventListener('blur', event => {
			// Strip out input mask prefix and placeholder characters.
			let ahpraNumber = event.target.value.replace('MED', '').replaceAll('_', '');
			if (ahpraNumber.length === 0) {
				// Remove the not empty class from the parent, as input mask breaks this logic.
				$ahpraField.parentNode.classList.remove('not-empty');
				return;
			}

			// Left pad with zeros.
			$ahpraField.value = 'MED' + ahpraNumber.padStart(10, '0');

			// set the not empty class on the parent, as input mask breaks this logic.
			$ahpraField.parentNode.classList.add('not-empty');
		});
	}
}

function sameAddressFieldLogic() {
	//The toggle field has the same-address-toggle class
	const sameAddressToggleFields = document.querySelectorAll('.gfield.same-address-toggle input');

	if (sameAddressToggleFields.length === 0) {
		return;
	}

	const addressToggleHandler = function ($toggleField) {
		//Fields to hide based on the toggle have the same-address-hide class
		const toggledAddressFields = $toggleField.closest('form')?.querySelectorAll('.gfield.same-address-hide');

		if (!toggledAddressFields || toggledAddressFields.length === 0) {
			return;
		}

		for (const $toggledAddressField of toggledAddressFields) {
			//use the screen reader only class to visually hide the fields, as display none prevents the hidden fields from being populated.
			$toggledAddressField.classList.toggle('sr-only', $toggleField.checked);
		}
	};

	for (const $sameAddressToggleField of sameAddressToggleFields) {
		$sameAddressToggleField.addEventListener('change', event => {
			addressToggleHandler(event.target);
		});

		addressToggleHandler($sameAddressToggleField);
	}
}

function trainingDateFieldPopulationLogic() {
	const $basicTrainingDateField = document.querySelector('.gfield.basic_training_date input'),
		$advancedTrainingDateField = document.querySelector('.gfield.advanced_training_date input'),
		$provisionalFellowDateField = document.querySelector('.gfield.provisional_fellow_date input'),
		$fellowshipAdmissionDateField = document.querySelector('.gfield.fellowship_admission_date input');

	if (!$fellowshipAdmissionDateField && !$basicTrainingDateField && !$advancedTrainingDateField && !$provisionalFellowDateField) {
		return;
	}

	// Make jQuery UI date pickers fire a change event on the input field when a value is selected in the calendar.
	jQuery(document).ready(function () {
		jQuery('.hasDatepicker').datepicker('option', 'onSelect', function (d, i) {
			// Only fire if the date value changes.
			if (d !== i.lastVal) {
				this.dispatchEvent(new Event('change'));
			}
		});
	});

	// Add listeners to populate logical default training dates from earlier entered dates.
	initTrainingDatePopulationForFields($basicTrainingDateField, $advancedTrainingDateField, 2);
	initTrainingDatePopulationForFields($advancedTrainingDateField, $provisionalFellowDateField, 2);
	initTrainingDatePopulationForFields($provisionalFellowDateField, $fellowshipAdmissionDateField, 1);

}

function initTrainingDatePopulationForFields($sourceField, $targetField, yearsToAdd) {
	if (!$sourceField || !$targetField) {
		return;
	}

	$sourceField.addEventListener('change', event => {
		const sourceDate = event.target.value;
		// Ensure the source field has a date set and the target is empty.
		if (0 === sourceDate.length || $targetField.value.length > 0) {
			return;
		}

		// Split the source value into separate date parts, so we can reformat.
		const dateParts = sourceDate.split('/');
		if (3 !== dateParts.length) {
			return;
		}
		//  Expecting dd/mm/yyyy format. Pad manually entered single digit values for day or month with a leading zero.
		const dateDay = String(dateParts[0]).padStart(2, '0'),
			dateMonth = String(dateParts[1]).padStart(2, '0'),
			dateYear = String(dateParts[2]);
		if (4 !== dateYear.length || 2 !== dateMonth.length || 2 !== dateDay.length) {
			return;
		}

		// Init a date object from an ISO format date string. Using a date object to handle things like leap years.
		let calculatedTargetDate = new Date(dateYear + '-' + dateMonth + '-' + dateDay);
		// Add the number of years required for the target date.
		calculatedTargetDate.setFullYear(calculatedTargetDate.getFullYear() + yearsToAdd);
		// Set the value in the target field. Left pad day and month with zeros where needed.
		$targetField.value = String(calculatedTargetDate.getDate()).padStart(2, '0') + '/' + String(calculatedTargetDate.getMonth() + 1).padStart(2, '0') + '/' + calculatedTargetDate.getFullYear();

		// Trigger a change event on the target field, as this won't happen automatically.
		$targetField.dispatchEvent(new Event('change'));
	});
}
